<template>
    <div class="o-layout o-layout--medium">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        @click="goBack()"
                    />

                    <title-text :title="PROVIDER.Portfolio.CourseCreate.Title" />
                </div>
            </div>

            <!-- show admin no access text if no provider or all providers is/are selected -->
            
            <div class="row" v-if="isAdministrator && selectedProvider && !selectedProvider.id">
                <div class="col-12">
                    <h6 class="qa-create-portfolio-title text-center">
                        {{ PROVIDER.Portfolio.CourseCreate.AdminNoAccessText }}
                    </h6>
                </div>
            </div>
            <!-- otherwise, show create course form -->
            <div class="row" v-else>
                
                <course-details />
            </div>
        </div>
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { mapState } from 'vuex'

import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'

import TitleText from '@/components/atoms/TitleText'
import CourseDetails from '@/pages/Portfolio/CourseEdit/CourseDetails.vue'
import UserService from '../../../../shared/services/UserService'

export default {
    name: 'CourseCreate',
    components: {
        TitleText,
        CourseDetails
    },
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            iconFont: 'keyboard_arrow_left',
            userService: new UserService(),
            isAdministrator: false
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator()
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider'])
    },
    methods: {
        goBack() {
            if (this.$route.name === 'course-create') {
                this.$router.push('/portfolio/overview')
            } else {
                this.$router.go(-1)
            }
        }
    }
}
</script>
